import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useContext } from 'react'
import { ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import { stateSvgList, CloseSvg, JumpSvg } from '@/utils/icoStatic'
import { LoadingOutlined } from '@ant-design/icons'
import useCommon from '@/hooks/useCommon'
import { ToastStyle } from './styled'
import Text from '@/components/Text'

const Toast:React.FC = ()=> {

  const timeout:any = useRef()

  const { t } = useTranslation()

  const { _getHashQueryAddress } = useCommon()

  const { toastConfig, isPC } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const antIcon = <LoadingOutlined style={{ fontSize: isPC ? 56 : 40 }} spin className='loading'/>
  
  const { status, address, kind, text, timing } = toastConfig

  const ShowSvg = stateSvgList[kind]

  const initParam = {
    status: false, 
    timing: false,
    text: '',
    address: ''
  }

  useEffect(() => {
    if(timing) {
      if(timeout.current) clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        configDispatch({ type: 'changeToast', payload: initParam })
      }, 3000)
      return () => {
        clearTimeout(timeout.current)
      }
    }
  },[timing])  
  
  const closeHandle = () => {
    configDispatch({ type: 'changeToast', payload: initParam })
  }

  const jumpHandel = () => window.open(_getHashQueryAddress(address))

  return (
    <ToastStyle style={status ? {} : {display: 'none'}} kind={kind}>
      {kind === 'wait' && (
        <>
          <Spin indicator={antIcon} />
          <div>
            <Text kind='Bold' size={isPC ? '18' : '14'}>{t('State.Wait')}</Text>
            <Text clsName='text-key1' kind='Bold' color='primary500' size={isPC ? '16' : '12'}>{text}</Text>
            <Text clsName='text-key2' color='gray500' size='12'>{t('Content.WalletTrade')}</Text>
          </div>
        </>
      )}
      {kind === 'submit' && (
        <>
          <ShowSvg />
          <div>
            <Text kind='Bold' size={isPC ? '18' : '14'}>{t('State.Submit')}</Text>
            <Text clsName='text-key3' kind='Bold' size={isPC ? '14' : '12'} color='primary500' callback={jumpHandel}>
              {t('Link.ViewOn')}
              <JumpSvg />
            </Text>
          </div>
        </>
      )}
      {kind === 'success' && (
        <>
          <ShowSvg />
          <div>
            <Text kind='Bold' size={isPC ? '18' : '14'}>{t('State.Success')}</Text>
            <Text clsName='text-key3' kind='Bold' size={isPC ? '14' : '12'} color='primary500' callback={jumpHandel}>
              {t('Link.ViewOn')}
              <JumpSvg />
            </Text>
          </div>
        </>
      )}
      {kind === 'fail' && (
        <>
          <ShowSvg />
          <div>
            <Text kind='Bold' size={isPC ? '18' : '14'}>{t('State.Failed')}</Text>
          </div>
        </>
      )}
      {kind === 'warning' && (
        <>
          <ShowSvg />
          <div>
            <Text kind='Bold' size={isPC ? '18' : '14'}>{text}</Text>
          </div>
        </>
      )}
      <CloseSvg onClick={() => closeHandle()} />
    </ToastStyle>
  )
}

export default Toast


import { rem } from '@/theme'
import styled from 'styled-components'

const FaucetContentStyle = styled.div`

	& > div.text-module {
		text-align: center;
	}

	div.content-bar {
		background: ${({ theme }) => theme.con_bg};
		border: 1px solid ${({ theme }) => theme.gray100};
		div.form-item {
			border-bottom: 1px solid ${({ theme }) => theme.gray100};
			div.input-module {
				width: 100%;
				input {
					background: ${({ theme }) => theme.text_bg};
					box-shadow: none;
				}
			}
			button.button-module {
				white-space: nowrap;
			}
		}
		div.table-item {
			border: 1px solid ${({ theme }) => theme.gray100};
			table {
				width: 100%;
				td {
					border-top: 1px solid ${({ theme }) => theme.gray100};
				}
				th:nth-child(1), td:nth-child(1) {
					text-align: left;
				}
				th:nth-child(2), td:nth-child(2){
					text-align: left;
				}
			}
		}
	}

	// PC
	@media screen and (min-width: 540px){
		& > div:nth-child(1) {
			margin-bottom: 15px;
		}
		div.ant-alert {
			margin-top: 50px;
		}
		div.content-bar { 
			margin-top: 50px;
			padding: 50px 0;
			border-radius: 24px;
			div.tab-item { 
				${({ theme }) => theme.align_between};
				padding: 0 60px;
			}
			div.form-item {
				${({ theme }) => theme.align};
				padding: 50px 60px 60px;
				button.button-module {
					height: 56px;
					margin-left: 20px;
					border-radius: 8px;
				}
			}
			div.table-item {
				margin: 60px 60px 0;
				border-radius: 24px;
				table {
					th {
						height: 56px;
					}
					td {
						height: 60px;
					}
					th:nth-child(1), td:nth-child(1) {
						padding-left: 50px;
					}
					th:nth-child(2), td:nth-child(2){
						padding-right: 50px;
					}
				}
			}
		}
	}
	// H5
	@media screen and (max-width: 540px){
		& > div:nth-child(1) {
			padding-top: ${rem(20)};
			margin-bottom: ${rem(5)};
		}
		div.ant-alert {
			margin-top: ${rem(20)};
		}
		div.content-bar { 
			margin-top: ${rem(20)};
			padding: ${rem(15)} 0;
			border-radius: ${rem(12)};
			div.tab-item {
				padding: 0 ${rem(20)};
				& > div:nth-child(2) {
					margin-top: ${rem(10)};
				}
			}
			div.form-item {
				padding: ${rem(20)};
				button.button-module {
					width: 100%;
					height: ${rem(45)};
					margin-top: ${rem(15)};
					border-radius: ${rem(8)};
				}
			}
			div.table-item {
				margin: ${rem(20)} ${rem(20)} 0;
				border-radius: ${rem(12)};
				table {
					th {
						height: ${rem(46)};
					}
					td {
						height: ${rem(50)};
					}
					th:nth-child(1), td:nth-child(1) {
						padding-left: ${rem(10)};
					}
					th:nth-child(2), td:nth-child(2){
						padding-right: ${rem(10)};
					}
				}
			}
		}
	}
`

export {
	FaucetContentStyle
}





import { rem } from '@/theme'
import styled from 'styled-components'

const lineHeightList:any = {
	12: 20,  
	13: 20,  
	14: 22,  
	15: 24,   
	16: 24,  
	18: 28,  
	20: 28,  
	22: 35,
	24: 38,  
	26: 42,
	28: 44,
	30: 48,  
	36: 44,
	40: 48,  
	44: 52,
	48: 58,
	54: 64,	
	56: 68
}


const TextStyle = styled.div<{
	$hovercolor:string
	$underline:string
	$justify:string 
	$clamp:string 
	color:string 
	type:string
	size:string 
}>`
	color: ${({ theme, color }) => theme[color]}; 
	${({ $justify }) => $justify && 'text-align: justify'};
	${({ $clamp, theme }) => $clamp && theme.clamp};
	& > span {
		${({ $underline }) => $underline ? `text-decoration: underline` : ''};
	}
	&:hover {
		${({ theme, $hovercolor }) => $hovercolor && `color: ${theme[$hovercolor]}`};
	}
	// PC
	@media screen and (min-width: 540px){ 
		font-size: ${({ size }) => size + 'px'};
		line-height: ${({ size }) => lineHeightList[size] + 'px'};
	}
	// H5
	@media screen and (max-width: 540px){
		font-size: ${({ size }) => `${rem(+size)}`};
		line-height: ${({ size }) => `${rem(lineHeightList[size])}`};
	}
`

const LightStyle = styled.span<{ type: string}>`
	&.zh-CN, &.zh-HK {
		${({ type }) => type==='specialNumber' && `font-family: NUMBER-SPECIAL-BIORHYME-REGULAR`};
		${({ type }) => type==='english' && `font-family: ENGLISH-SFPROTEXT-REGULAR`};
		${({ type }) => !['specialNumber', 'english'].includes(type) && `font-family: CHINESE-HYQIHEI-60S`};
	}
	&.en {
		${({ type }) => type==='specialNumber' && `font-family: NUMBER-SPECIAL-BIORHYME-REGULAR`};
		${({ type }) => type==='specialEnglish' && `font-family: ENGLISH-SPECIAL-CLASHDISPLAY-REGULAR`};
		${({ type }) => !['specialEnglish', 'specialNumber'].includes(type) && `font-family: ENGLISH-SFPROTEXT-REGULAR`};
	}
`

const BoldStyle = styled.span<{ type: string}>`
	&.zh-CN, &.zh-HK {
		${({ type }) => type==='specialNumber' && `font-family: NUMBER-SPECIAL-BIORHYME-BOLD`};
		${({ type }) => type==='english' && `font-family: ENGLISH-SFPROTEXT-BOLD`};
		${({ type }) => !['specialNumber', 'english'].includes(type) && `font-family: CHINESE-HYQIHEI-60S;font-weight: bold`};
	}
	&.en {
		${({ type }) => type==='specialNumber' && `font-family: NUMBER-SPECIAL-BIORHYME-BOLD`};
		${({ type }) => type==='specialEnglish' && `font-family: ENGLISH-SPECIAL-CLASHDISPLAY-BOLD`};
		${({ type }) => !['specialEnglish', 'specialNumber'].includes(type) && `font-family: ENGLISH-SFPROTEXT-BOLD`};
	}
`

export {
	LightStyle,
	TextStyle,
	BoldStyle
}





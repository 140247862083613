import React, { useState, useEffect, useRef } from 'react'
import { MoreSvg, LanguageSvg, NewsSvg, Left2Svg, Right2Svg } from '@/utils/icoStatic'
import { RouterProps } from '@/utils/interface'
import { langList } from '@/utils/comStatic'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { MoreStyle } from './styled'

const More:React.FC<RouterProps> = ({ t, i18n, location, navigate }) => {

  const moreRef:any = useRef()

  const { linkList } = useCommon()

  // 更多状态
  const [moreShow, setMoreShow] = useState(false)
  
  // 语言状态
  const [langShow, setLangShow] = useState(false)

  useEffect(() => { 
    const handle = (event:any) => {
      if(!moreRef.current?.contains(event.target)) {
        setMoreShow(false)
        setLangShow(false)
      }
    }
    window.addEventListener('click', handle)
    return () =>  window.removeEventListener('click', handle)
  })

  // 展示更多列表操作
  const showMoreHandle = () => {
    if (langShow) {
      setMoreShow(false)
    } else {
      setMoreShow(!moreShow)
    }
    setLangShow(false)
  }

  // 展示语言下拉列表
  const showLangListHandle = (e:any) => {
    e.stopPropagation()
    setMoreShow(false)
    setLangShow(true)
  }
  
  // 返回更多列表
  const backHandle = (e:any) => {
    e.stopPropagation()
    setMoreShow(true)
    setLangShow(false)
  }

  // 关闭更多操作
  const closeMoreHandle = () => {
    setMoreShow(false)
    setLangShow(false)
  }

  // 渲染语言列表
  const langListRender = () => {
    const list:any = []
    Object.keys(langList).forEach((item, i) => {
      const isActive = i18n.language === item
      list.push(
        <li key={`langListRender${i}`} onClick={() => changeLanguageHandle(item)}>
          <Text kind={isActive ? 'Bold' : 'Light'} color={isActive ? 'text_main' : 'gray500'} >{langList[item]['name']}</Text>
        </li>
      )
    })
    return list
  }

  // 渲染语言列表
  const moreListRender = () => {
    const moreList = [
      {text: t('Link.News'), urlSign: 'News', pic: <NewsSvg />},
      {text: t('Button.SetLang'), urlSign: '', pic: <LanguageSvg />},
    ]
    const list:any = []
    moreList.forEach((item, i) => {
      const { pic, text, urlSign } = item
      list.push(
        <li key={`moreListRender${i}`} onClick={(e) => !urlSign ? showLangListHandle(e) : window.open(linkList[urlSign])}>
          <div>
            {pic}
            <Text color='gray500'>{text}</Text>
          </div>
          { !urlSign && <Right2Svg /> }
        </li>
      )
    })
    return list
  }

  // 修改语言
  const changeLanguageHandle = (lang:string) => {
    navigate(`${location.pathname}?lang=${lang}`)
    i18n.changeLanguage(lang)
    closeMoreHandle()
  }

  return (
    <MoreStyle ref={moreRef} className={i18n.language}>
      <MoreSvg onClick={showMoreHandle} />
      { moreShow && <figcaption><ul className='first-bar'>{moreListRender()}</ul></figcaption> }
      {
        !moreShow && langShow && (
          <figcaption>
            <ul className='second-bar'>
              <li onClick={backHandle}><Left2Svg /></li>
              {langListRender()}
            </ul>
          </figcaption>
        )
      }
    </MoreStyle>
  )
}
  
export default withRouter(More)

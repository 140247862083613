import { rem } from '@/theme'
import styled from 'styled-components'

const ScTabStyle = styled.div`

	${({ theme }) => theme.align}
	// 类型one
	div.one-normal {
		border-bottom-style: solid;
		border-bottom-color: transparent;
		border-bottom-width: 2px;
	}
	div.one-active {
		border-bottom-color: ${({ theme }) => theme.primary500};
	}
	// PC
	@media screen and (min-width: 540px) { 
		// 类型one
		div.one-normal {
			padding: 8px 0 6px;
		}
		div.one-normal:not(:last-child) {
			margin-right: 40px;
		} 
	}

	// H5
	@media screen and (max-width: 540px) { 
		// 类型one
		div.one-normal {
			padding: ${rem(8)} 0 ${rem(6)};
		}
		div.one-normal:not(:last-child) {
			margin-right: ${rem(30)};
		} 
	}
`

export {
  ScTabStyle
}



import { Fragment } from 'react'
import ReactDOM from 'react-dom/client'
import ThemeProvider, { FixedGlobalStyle } from './theme'
import reportWebVitals from './reportWebVitals'
import Provider from './reducer'
import App from '@/views/App'
import './theme/font.css'
import './i18n'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Fragment>
    <FixedGlobalStyle />
    <Provider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
  </Fragment>
)

reportWebVitals()

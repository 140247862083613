import { rem } from '@/theme'
import styled from 'styled-components'

const MoreStyle = styled.figure`

	position: relative;
	z-index: 2;
	figcaption {
		position: absolute;
		right: 0;
		background: ${({ theme }) => theme.white};
		border: 1px solid ${({ theme }) => theme.gray100};
		ul {
			li {
				& > div {
					${({ theme }) => theme.align};
				}
			}
		}
		ul.first-bar {
			li {
				${({ theme }) => theme.align_between};
			}
		}
	}

	// PC
	@media screen and (min-width: 540px){
		& > svg {
			width: 40px;
			height: 40px;
			cursor: pointer;
		}
		figcaption {
			width: 160px;
			padding: 10px 0;
			top: 52px;
			border-radius: 12px;
			ul {
				li {
					padding: 8px 12px;
					cursor: pointer;
				}
				li:hover {
					background: ${({ theme }) => theme.gray50};
				}
			}
			ul.first-bar {
				li {
					& > div { 
						svg {
							width: 20px;
							height: 20px;
							margin-right: 10px;
						}
					}
					& > svg {
						width: 14px;
						height: 14px;
					}
				}
			}
			ul.second-bar {
				li {
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		font-size: 0;
		& > svg {
			width: ${rem(24)};
			height: ${rem(24)};
		}
		figcaption {
			width: ${rem(160)};
			padding: ${rem(10)} 0;
			top: ${rem(35)};
			right: 0;
			border-radius: ${rem(12)};
			ul {
				li {
					padding: ${rem(8)} ${rem(12)};
				}
			}
			ul.first-bar {
				li {
					& > div { 
						svg {
							width: ${rem(20)};
							height: ${rem(20)};
							margin-right: ${rem(10)};
						}
					}
					& > svg {
						width: ${rem(14)};
						height: ${rem(14)};
					}
				}
			}
			ul.second-bar {
				li {
					svg {
						width: ${rem(14)};
						height: ${rem(14)};
					}
				}
			}
		}
  }
`
export {
	MoreStyle
}

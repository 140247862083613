import { rem } from '@/theme'
import styled from 'styled-components'

const ToastStyle = styled.div<{kind:string}>`

	${({ theme }) => theme.align};
	position: fixed;
	z-index: 999;
	background: ${({ theme, kind }) => theme.toast_bg[kind]};
	& > div:nth-child(2) {
		div.text-key3 {
			svg {
				position: relative;
			}
		}
	}
	& > svg:last-child {
		position: absolute;
	}
	.loading {
		color: ${({ theme }) => theme.primary500};
	}

	// PC
	@media screen and (min-width: 540px){
		right: 20px;
		top: 20px;
		padding: 20px 24px;
		border-radius: 18px;
		& > svg:first-child {
			width: 48px;
			height: 48px;
		}
		& > div:nth-child(2) {
			margin-left: 24px;
			margin-right: 50px;
			div.text-key1 {
				padding-top: 2px;
			}
			div.text-key2 {
				padding-top: 2px;
			}
			div.text-key3 {
				margin-top: 2px;
				svg {
					top: 2px;
					width: 14px;
					height: 14px;
					margin-left: 5px;
				}
			}
		}
		& > svg:last-child {
			right: 24px;
			top: 24px;
			width: 14px;
			height: 14px;
			cursor: pointer;
		}
		.loading {
			margin-right: 15px;
		}
	}

	/* H5 */
	@media screen and (max-width: 540px){ 
		right: ${rem(16)};
		top: ${rem(60)};
		padding: ${rem(10)} ${rem(15)};
		border-radius: ${rem(15)};
		& > svg:first-child  {
			width: ${rem(30)};
			height: ${rem(30)};
		}
		& > div:nth-child(2) {
			margin-left: ${rem(15)};
			margin-right: ${rem(30)};
			div.text-key1 {
				padding-top: ${rem(2)};
			}
			div.text-key2 {
				padding-top: ${rem(2)};
			}
			div.text-key3 {
				margin-top: ${rem(2)};
				svg {
					top: ${rem(2)};
					width: ${rem(12)};
					height: ${rem(12)};
					margin-left: ${rem(5)};
				}
			}
		}
		& > svg:last-child  {
			top: ${rem(12)};
			right: ${rem(12)};
			width: ${rem(10)};
			height: ${rem(10)};
		}
		.loading {
			margin-right: ${rem(15)};
		}
	}
`

export {
  ToastStyle
}



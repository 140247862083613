import {createGlobalStyle} from 'styled-components'

const resetStyle = createGlobalStyle`

    * {
      box-sizing: border-box;
    }

    html, body {
      line-height: 1;
    }

    body, html, blockquote, ol, ul, dl, li, dt, dd, h1, h2, h3, h4, h5, h6, p, th, td, dl, dd, form, fieldset, legend, input, textarea, select, figure {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
    }

    button {
      border: 0;
      cursor: pointer;
      background: transparent;
    }

    input {
      border: 0;
      background: transparent;
    }

    article, aside, details, hgroup, menu, section {
      display: block
    }

    h1, h2, h3, h4, h5 {
      font-weight: normal;
    }

    a, button, img {
      border: 0;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0
    }

    input, button, select, textarea {
      outline: none;
    }

    input, textarea {
      -webkit-appearance: none;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
    }

    a {
      text-decoration: none;
    }

    #root {
      min-height: 100vh;
      height: auto;
    }

    .cp {
      cursor: pointer;
    }

    .dn {
      display: none;
    }

    @media screen and (max-width: 540px) { 
      html {
        font-size: 20vw; 
        // 同时，通过Media Queries 限制根元素最大最小值
        @media screen and (max-width: 320px) {
          font-size: 64px;
        }
        @media screen and (min-width: 540px) {
          font-size: 108px;
        }
      }
      // body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
      body {
        max-width: 540px;
        min-width: 320px;
      }
    }
`

export default resetStyle
const faucetState:any = {
  // 当前tab的key(币种名称)
  tabKey: '',
  // 当前tab的value（币种地址）
  tabValue: '',
  // 币种列表
  tokenList: [],
  // 用户可以领取某一代币数量
  mintLimitAmount: '0',
  // 导航链名称
  navText: '',
  // 选择网络
  selectNetwork: null,
  // 交易记录
  transactionsList: [],
  // 接收地址
  receiveAddress: '',
  // 下一次可MINT剩余时间
  nextMintLastTime: 0
}

const faucetReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    case 'changeReset':
      break;
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  faucetReducer,
  faucetState
}
import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { ScTabStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps extends RouterProps {
  currentKey: string
  callback: any
  data:any
}

const ScTab:React.FC<ChildProps> = ({ data, callback, currentKey})=> {

  const { isPC } = useContext(ConfigContext)

  const contentRender = (item:any, index:number) => {
    const { key, text, value } = item
    const isActive = currentKey === key

      return (
        <Text 
          color={isActive ? 'text_main' : 'tab_default'}
          clsName={`one-normal ${isActive ? 'one-active' : ''}`}
          callback={() => callback(key)}
          size={isPC ? '18' : '16'}
          key={`${text}_${index}`} 
          hovercolor='text_main'
          type='specialEnglish'
          kind='Bold'>
          {text ? text : value}
        </Text>
      )
  }

  const tabRender = () => {
    const list:any = []
    data.forEach((item:any, index:number) => {
      list.push(contentRender(item, index))
    })
    return list
  }

  return (
    <ScTabStyle className='tab-module'>{tabRender()}</ScTabStyle>
  )
}

export default withRouter(ScTab)


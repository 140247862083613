import { useEffect, useContext } from 'react'
import useContractMock from '@/hooks/useContract/useContractMock'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import { tokenAddressList } from '@/utils/addressStatic'
import { ConfigContext } from '@/utils/contextStatic'
import useCommon from '@/hooks/useCommon'

/**
 * 初始化 - Faucet
 */

export default function useInitFaucet(faucetData:any, faucetDispatch:any, params:any) {

  const { chainSymbol } = params

  const { _isAddress } = usePublicEthers()

  const { _getChainProperty } = useCommon()
  
  const configData = useContext(ConfigContext)

  const { _getMintLimit, _getTimeUntilNextMint } = useContractMock()

  const { tabKey, tabValue, receiveAddress, selectNetwork } = faucetData

  const { chainId } = configData

  // 获取 - tokenList/tabKey
  useEffect(() => {
    const name = _getChainProperty(chainSymbol, 'nav_text', 'chain_symbol')
    const chain =_getChainProperty(chainSymbol, 'chain_id', 'chain_symbol')
    const list:any = []
    let arr = tokenAddressList[chain]
    arr = arr ? Object.keys(arr) : []
    if(arr.length > 0) {
      for(const item of arr) {
        list.push({ key: item, value: tokenAddressList[chain][item], text: item })
      }
      // 设置 - tokenList
      faucetDispatch({ type: 'tokenList', payload: list })
      // 设置 - tabKey
      faucetDispatch({ type: 'tabKey', payload: list[0]['key'] })
      // 设置 - tabValue
      faucetDispatch({ type: 'tabValue', payload: list[0]['value'] })
    }
    // 设置 - 导航名称
    faucetDispatch({ type: 'navText', payload: name })
    // 设置 - 选择链
    faucetDispatch({ type: 'selectNetwork', payload: chain })
    // 设置 - 接收地址
    faucetDispatch({ type: 'receiveAddress', payload: '' })
  }, [chainSymbol])

  // 获取 - 交易记录
  useEffect(() => {
    const getTransactionsData = () => {
      let list:any = []
      const transactionsData = window.localStorage.getItem(`${receiveAddress.toLowerCase()}_${tabValue.toLowerCase()}_transactions`)
      if(transactionsData) list = JSON.parse(transactionsData)
      faucetDispatch({ type: 'transactionsList', payload: list })
    }
    if(_isAddress(receiveAddress)) getTransactionsData()
  }, [chainSymbol, tabKey, receiveAddress])

  // 获取 - 用户可以领取某一代币数量
  useEffect(() => {
    const getMintLimit = async() => {
      const mintLimitAmount = await _getMintLimit(tabValue)
      faucetDispatch({ type: 'mintLimitAmount', payload: mintLimitAmount })
    }
    if(tabKey && +chainId === selectNetwork ) {
      getMintLimit()
    } else {
      faucetDispatch({ type: 'mintLimitAmount', payload: '0' })
    }
  }, [selectNetwork, tabKey, chainId])

  // 获取 - 用户下一次可以mint的剩余时间
  useEffect(() => {
    const getMintLimit = async() => {
      const nextMintLastTime = await _getTimeUntilNextMint(tabValue, receiveAddress)
      faucetDispatch({ type: 'nextMintLastTime', payload: Number(nextMintLastTime) })
    }
    if(tabKey && +chainId === selectNetwork && _isAddress(receiveAddress)) {
      getMintLimit()
    } else {
      faucetDispatch({ type: 'nextMintLastTime', payload: 0 })
    }
  }, [selectNetwork, tabKey, chainId, receiveAddress])
}

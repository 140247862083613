import styled from 'styled-components'

const CopyStyle = styled.div`

	position: relative;
	${({ theme }) => theme.align};
	input {
		position: absolute;
		z-index: -1;
		left: 0;
		opacity: 0;
		right: 0
	}
`


export {
	CopyStyle
}





/**
 * 时间转换
 * @param {String} data   //毫秒时间戳
 * @param {Number} type   //展示方式
 */
const _formatDate = (data:number, type:number) => {
	let showText = ''
	const date = new Date(+data)
	let y = date.getFullYear()
	let m:any = date.getMonth() + 1
	m = m < 10 ? ('0' + m) : m
	let d:any = date.getDate()
	d = d < 10 ? ('0' + d) : d
	let h:any = date.getHours()
	h = h < 10 ? ('0' + h) : h
	let minute:any = date.getMinutes()
	minute = minute < 10 ? ('0' + minute) : minute
	let second:any = date.getSeconds()
	second = second < 10 ? ('0' + second) : second
	switch(type) {
		case 1:
			showText = y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
			break;
		case 2:
			showText = y + '-' + m + '-' + d + ' ' + h + ':' + minute
			break;
		default:
			showText = h + ':' + minute + ':' + second
	}
	return showText
}

/**
 * 获取url中"?"符后的字串
 * @param {String}  url  链接地址
 */
const _getParamByUrl = (url:string) => {
	let theRequest:any = {}
	let index = url.indexOf("?")
	if (index !== -1) {
	    let str = url.substr(index + 1)
	    let strs = str.split("&")
	    for(let i = 0; i < strs.length; i ++) {
		   theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
	    }
	}
	return theRequest
}

/**
 * 每三位隔一逗号
 * @param {String/Number} str    需要格式化得数字
 * @param {Number}  index        保留小数位数
 */
const _numComma = (num: string|number, index=2) => {
	const isNegative = Number(num) < 0
	let str = num ? num.toString() : '0'
	str = isNegative ? str.replace('-', '') : str
	let newStr = ""
	let count = 0
	// 当数字是整数
	if (str && str?.indexOf(".") === -1) {
		for (let i = str.length - 1; i >= 0; i--) {
			if (count % 3 === 0 && count !== 0) {
				newStr = str.charAt(i) + "," + newStr
			} else {
				newStr = str.charAt(i) + newStr
			}
			count++
		}
		str = newStr
		return `${isNegative ? '-' : ''}${str}`
	}
	// 当数字带有小数
	else {
		let decimal = str.split('.')[1]
		decimal = decimal.length > index ? decimal.substring(0,index) : decimal
		for (let i = str?.indexOf(".") - 1; i >= 0; i--) {
			if (count % 3 === 0 && count !== 0) {
				newStr = str.charAt(i) + "," + newStr
			} else {
				newStr = str.charAt(i) + newStr
			}
			count++
		}
		var len = decimal.split("0").length - 1
		decimal = len !== decimal.length ? `.${decimal.replace(/(0+)$/g,"")}` : ''
		str = newStr + decimal
		if (index === 0) str = str.split('.')[0]
		return `${isNegative ? '-' : ''}${str}`
	}
}

/**
 * 地址截取
 * @param {String} str  //地址
 * @param {String} ind  //截断位数
 */
 const _substrAddress = (str:string, ind:number) => {
	return str ? (str.substring(0,ind)+'...'+str.substring(str.length - ind)) : ''
}

/**
 * 创建数组
 * @param {Number}  size   // 数组长度
 */
const _loaderArray = (size:number) => {
	return Array.from({length: size}, (_, index) => index + 1)
}


export {
	_substrAddress,
	_getParamByUrl,
	_loaderArray,
	_formatDate,
	_numComma
}

import i18n from "i18next"
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { _getParamByUrl } from '@/utils/comMethod'
import { langList } from '@/utils/comStatic'
import zhCnTrans from "@/locales//zh.json"
import enUsTrans from "@/locales/en.json"
import zhHkTrans from "@/locales/hk.json"

// url参数对象
const param = _getParamByUrl(window.location.href)
// 本地语言
const localLang = window.localStorage.getItem('i18nextLng')

// 根据url是否带语言参数处理
if (Object.keys(param).length) {
  const la = param['lang']
  // URL存在语言
  if (Object.keys(langList).includes(la)) {
    if(localLang !== la) window.localStorage.setItem('i18nextLng', la)
  }
}

i18n.use(LanguageDetector) //当前浏览器语言
.use(initReactI18next) //init i18next
.init({
  //引入资源文件
  resources: {
    "en": { translation: enUsTrans },
    "zh-CN": { translation: zhCnTrans },
    "zh-HK": { translation: zhHkTrans },
  },
  //选择默认语言，选择内容为上述配置中的key，即en/zh
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})
export default i18n

import React, { useState, useEffect } from 'react'
import { _check_proving, _check_num, _check_special } from '@/utils/checkMethod'
import { FormInputStyle } from './styled'
import Text from '@/components/Text'


interface ChildProps {
  placeholder?: string
  disabled?: boolean
  maxvalue?: string 
  decimal?: number    
  callback?: any
  init?: string
  type?: string
  size?: string
}

const FormInput:React.FC<ChildProps> = ({
  disabled=false, 
  size='normal',
  type='price', 
  placeholder, 
  maxvalue='', 
  decimal=4,
  callback, 
  init='', 
})=> {

  const [con, setCon] = useState<string>('') 

  useEffect(() => {
    setCon(init)
  },[init]) 

  const numHandle = (e:any) => {
    let val = e?.target?.value
    if(type === 'price') val = _check_proving(val, decimal)
    if(type === 'number') val = _check_num(val)
    if(type === 'normal') val = _check_special(val)
    setCon(val)
    if(callback) callback(val)
  }

  const maxHandle = () => {
    setCon(maxvalue)
    if(callback) callback(maxvalue)
  }

  return (
    <FormInputStyle className='input-module' size={size} $maxvalue={maxvalue}>
      <input 
        onChange={(e)=>numHandle(e)}
        placeholder={placeholder}
        disabled={disabled} 
        value={con} />
      {maxvalue && <Text kind='Bold' size='12' type='english' callback={maxHandle}>MAX</Text>}
    </FormInputStyle>
  )
}

export default FormInput

import { Layouts } from './styled'
import { css } from 'styled-components'
import { rem } from '@/theme'

const layouts = (): Layouts => {

  return {

    h5MainContent: css`
      width: ${rem(343)};
      margin: 0 ${rem(16)};
    `,

    // 内容布局
    mainContent: css`
      max-width: 1266px;
      margin: 0 auto;
    `,

    // 水平居中
    x_center: css`
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    `,

    // 垂直居中
    y_center: css`
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    `,
    
    // 顶部对齐
    start: css`
      display: flex;
      align-items: flex-start;
    `,

    // 底部对齐
    end: css`
      display: flex;
      align-items: flex-end;
    `,

    // 垂直居中
    align: css`
      display: flex;
      align-items: center;
    `,

    // 水平两端对齐
    horizontal_between: css`
      display: flex;
      justify-content: space-between;
    `,

    // 水平均分对齐
    horizontal_around: css`
      display: flex;
      justify-content: space-around;
    `,

    // 水平居中对齐
    horizontal_center: css`
      display: flex;
      justify-content: center;
    `,

    // 居中对齐
    align_center: css`
      display: flex;
      align-items: center;
      justify-content: center;
    `,

    // 居中右对齐
    align_end: css`
      display: flex;
      align-items: center;
      justify-content: end;
    `,

    // 两边对齐
    align_between: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,

    // 两边顶部对齐
    start_between: css`
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    `,

    // 两边底部对齐
    end_between: css`
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    `,

    // 均分对齐
    align_around: css`
      display: flex;
      align-items: center;
      justify-content: space-around;
    `,

    // 单行溢出点点点
    clamp: css`
      text-overflow :ellipsis;
      white-space :nowrap; 
      overflow : hidden; 
    `,

    // 2行溢出点点点
    clamp2: css`
      word-wrap:break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    `,

    // 3行溢出点点点
    clamp3: css`
      word-wrap:break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    `,

    // 4行溢出点点点
    clamp4: css`
      word-wrap:break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    `
  }
}

export default layouts
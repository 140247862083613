import React from 'react'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { LogoSvg } from '@/utils/icoStatic'
import useCommon from '@/hooks/useCommon'
import { LogoStyle } from './styled'

const Logo:React.FC<RouterProps> = ({ navigate })=> {
  
  const { linkList } = useCommon()

  const jumpHandle = () => { navigate(linkList['Home']) }

  return (
    <LogoStyle onClick={jumpHandle}>
      <LogoSvg />
    </LogoStyle>
  )
}

export default withRouter(Logo)

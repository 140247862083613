import { rem } from '@/theme'
import styled from 'styled-components'

const LogoStyle = styled.div`

	font-size: 0;

	@media screen and (min-width: 540px) { 
		svg {
			width: 98px;
			height: 50px;
			cursor: pointer;
		}
	}
	@media screen and (max-width: 540px) { 
		svg {
			width: ${rem(78)};
			height: ${rem(40)};
		}
	}
`

export {
  LogoStyle
}
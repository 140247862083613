import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FormButtonStyle } from './styled'

interface ChildProps {
  stateless?: boolean
  disabled?:boolean
  callback?: any
  children: any
  kind?: string
  size?: string
  svgCon?: any
}

const FormButton:React.FC<ChildProps> = (
  { 
    stateless=false,
    disabled=false,
    children=<></>, 
    kind='primary',
    size='normal',
    callback,
    svgCon=null
  }
) => {

  const antIcon = <LoadingOutlined style={{ fontSize: 15, marginRight: '8px' }} spin />

  const btnHandle = (e:any) => {
    if(callback) callback(e)
  }

  return (
    <FormButtonStyle 
      className={`button-module ${kind}`} 
      $stateless={stateless}
      disabled={disabled}
      onClick={btnHandle} 
      size={size} 
      kind={kind}>
        { ['loading'].includes(kind) ? <Spin indicator={antIcon} /> : ( svgCon && svgCon ) }
        { children }
    </FormButtonStyle>
  )
}

export default FormButton
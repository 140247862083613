import { ethers } from "ethers"
import { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import MOCKERC20ABI from '@/contract/mockErc20.json'
import { chainCoin } from '@/utils/addressStatic'
import usePublicEthers from './usePublicEthers'
import ERC20ABI from '@/contract/erc20.json'

/**
 * Erc20基础方法
 */
export default function usePublicErc20() {

  const { account } = useContext(ConfigContext)

  const { _getSigner, _outPutFormat, _getBrowserProvider } = usePublicEthers()
  
  /**
   * 获取 - 实例化合约
   * @param {String}  contractAddr    合约地址
   * @param {Boolean} type            合约类型 
   * @param {String}  kind            provide类型: 1:rpc, 2:浏览器 
   */ 
  const _getContract = async (contractAddr:string, type='ERC20ABI') => {
    // console.log('合约【获取 - 实例化合约(入参)】:', { contractAddr, type })
    try {
      const provider = await _getSigner()
      let contractList:any = {
        MOCKERC20ABI,
        ERC20ABI
      }
      return new ethers.Contract(contractAddr, contractList[type], provider)
    } catch(error) {
      console.error('合约调用异常【获取 - 实例化合约】:', error)
    }
  }

  /**
   * 获取用户单一币种资产
   * @param {String} tokenAddress    代币地址
   */ 
  const _getBalacneOperate = async(tokenAddress=chainCoin) => {
    console.log('合约【获取 - 用户单一币种资产(入参)】(入参):', { account, tokenAddress })
    try {
      let decimals = 18 
      let balance:any = '0'
      if(tokenAddress !== chainCoin) {
        const tokenContract:any = await _getContract(tokenAddress)
        balance = await tokenContract['balanceOf'](account)
        decimals = await _getDecimals(tokenAddress)
      } else {
        const provider = await _getBrowserProvider()
        balance = await provider.getBalance(account)
      }
      return _outPutFormat(balance, decimals)
    } catch(error) {
      console.error('合约调用异常(获取 - 用户单一币种资产):', error)
    }
  }

  /**
   * 获取 - 币种精度
   * @param {String} tokenAddress    代币地址
   */ 
  const _getDecimals = async(tokenAddress:string) => {
    // console.log('合约【获取 - 币种精度(入参)】:', { tokenAddress })
    try {
      const tokenContract:any = await _getContract(tokenAddress)
      return tokenContract.decimals()
    } catch(error) {
      console.error('合约调用异常【获取 - 币种精度】:', error)
    }
  }

  return {
    _getBalacneOperate,
    _getDecimals,
    _getContract,
  }
}

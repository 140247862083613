// Logo
import { ReactComponent as FooterLogoSvg } from '@/assets/img/Logo/footer_logo.svg'
import { ReactComponent as LogoSvg } from '@/assets/img/Logo/logo.svg'

// Icon
import { ReactComponent as LanguageSvg } from '@/assets/img/Icon/language.svg'
import { ReactComponent as WalletSvg } from '@/assets/img/Icon/wallet.svg'
import { ReactComponent as CloseSvg } from '@/assets/img/Icon/close.svg'
import { ReactComponent as UserSvg } from '@/assets/img/Icon/user.svg'
import { ReactComponent as NewsSvg } from '@/assets/img/Icon/news.svg'

// Tool
import { ReactComponent as Link2Svg } from '@/assets/img/Tool/link2.svg'
import { ReactComponent as CopySvg } from '@/assets/img/Tool/copy.svg'
import { ReactComponent as MoreSvg } from '@/assets/img/Tool/more.svg'
import { ReactComponent as JumpSvg } from '@/assets/img/Tool/jump.svg'

// Arrow
import { ReactComponent as Right2Svg } from '@/assets/img/Arrow/right2.svg'
import { ReactComponent as Left2Svg } from '@/assets/img/Arrow/left2.svg'

// Wallet
import { ReactComponent as WalletConnectSvg } from '@/assets/img/Wallet/wallet_connect.svg'
import { ReactComponent as TokenPocketSvg } from '@/assets/img/Wallet/token_pocket.svg'
import { ReactComponent as TrustWalletSvg } from '@/assets/img/Wallet/trust_wallet.svg'
import { ReactComponent as HyperpaySvg } from '@/assets/img/Wallet/hyperpay.svg'
import { ReactComponent as MetamaskSvg } from '@/assets/img/Wallet/metamask.svg'
import { ReactComponent as CoinhubSvg } from '@/assets/img/Wallet/coinhub.svg'
import { ReactComponent as BitkeepSvg } from '@/assets/img/Wallet/bitkeep.svg'

// State
import { ReactComponent as SendSuccessSvg } from '@/assets/img/State/send_success.svg'
import { ReactComponent as SendWarningSvg } from '@/assets/img/State/send_warning.svg'
import { ReactComponent as SendSubmitSvg } from '@/assets/img/State/send_submit.svg'
import { ReactComponent as SendFailSvg } from '@/assets/img/State/send_fail.svg'

// 获取 - 钱包相关图片
const walletSvgList:any = {
	"MetaMask": MetamaskSvg,
	"OKX Wallet": MetamaskSvg,
	"Token Pocket": TokenPocketSvg,
	"Coinhub": CoinhubSvg,
	"Trust Wallet": TrustWalletSvg,
	"Hyperpay": HyperpaySvg,
	"WalletConnect": WalletConnectSvg,
	"Bitkeep": BitkeepSvg,
}

// 获取 - 状态相关图片
const stateSvgList:any = {
	"wait": "",
	"submit": SendSubmitSvg,
	"success": SendSuccessSvg,
	"fail": SendFailSvg,
	"warning": SendWarningSvg
}

export {
	walletSvgList,
	FooterLogoSvg,
	stateSvgList,
	LanguageSvg,
	Right2Svg,
	WalletSvg,
	Left2Svg,
	Link2Svg,
	CloseSvg,
	JumpSvg,
	MoreSvg,
	NewsSvg,
	LogoSvg,
	CopySvg, 
	UserSvg,
}



import { useEffect, useContext } from 'react'
import { FaucetContext } from '@/utils/contextStatic'
import { ConfigContext } from '@/utils/contextStatic'

/**
 * 设置按钮状态 2:加載中 3:确认 5:钱包未链接 6: 链不匹配 
 * @param {String} handler          // 方法
 */

export default function useBtnFaucet(handler: any) {

  const faucetData = useContext(FaucetContext)

  const { receiveAddress, selectNetwork } = faucetData

  const { account, chainId } = useContext(ConfigContext)

  useEffect(() => {
    const initFun = async() => {
      if(account) {
        if(+chainId === selectNetwork) {
          handler(3)
        } else {
          handler(6)
        }
      } else {
        handler(5)
      }
    }
    initFun()
  },[receiveAddress, chainId, account, selectNetwork]) 
}
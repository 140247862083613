import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextStyle, LightStyle, BoldStyle } from './styled'
import useCommon from '@/hooks/useCommon'

interface ChildProps {
  underline?: string
  hovercolor?: string
  justify?: string
  clsName?: string
  clamp?: string
  children?: any
  callback?: any
  color?: string
  size?: string
  kind?: string
  type?:string
  en?: string
}

const Text:React.FC<ChildProps> = ({ 
  color='text_main', 
  children=<></>, 
  hovercolor='',
  type='normal',
  underline='',
  kind='Light',
  justify='', 
  clsName='', 
  size='14',
  clamp='',
  callback
}) => {

  const { i18n } = useTranslation()

  const { isEnglish } = useCommon()

  const getJustifyState = () => {
    if(justify && isEnglish) return '1'
    return ''
  }

  return (
    <TextStyle 
      className={`text-module ${clsName} ${callback ? 'cp' : ''}`} 
      $underline={underline}
      $justify={getJustifyState()}
      $hovercolor={hovercolor}
      onClick={callback} 
      $clamp={clamp}
      color={color} 
      type={type}
      size={size}>
        {
          kind === 'Light' && (
            <LightStyle className={`${i18n.language}`} type={type}>
              {children}
            </LightStyle>
          )
        }
        {
          kind === 'Bold' && (
            <BoldStyle className={`${i18n.language}`} type={type}>
              {children}
            </BoldStyle>
          )
        }
    </TextStyle>
  )
}

export default Text
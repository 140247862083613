import { message } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigContext } from '@/utils/contextStatic'
import usePublicWallet from '@/hooks/usePublic/usePublicWallet'

/**
 * 切换链操作
 * @param {Number}  networkId  // 网络ID
 * @param {Void}    handler    // 方法
 */

export default function useToggleChain(networkID:number, handler: any) {

  const t:any = useTranslation()['t']

  const { _changeChain, _addChain } = usePublicWallet()

  const { account, supportChainList } = useContext(ConfigContext)

  const toggleChainHandle = async() => {
    const chainInfo = supportChainList.find((item:any) => item.chain_id === networkID)
    const { chain_id, chain_name, chain_symbol, currency, rpc_node } = chainInfo
    const chainId = `0x${chain_id.toString(16)}`
    const chainParam = {
      chainId, 
      chainName: chain_name, 
      name: chain_symbol, 
      symbol: currency, 
      rpcUrls: [rpc_node], 
    }
    if (account) {
      handler(2)
      _changeChain(chainId).then(() => {
        message.success(t("Content.SwitchNetworkDesc", { chain: chain_name }), 2, () => handler(3))
      }).catch ((changeError) => {
        if(changeError.code === 4902) {
          _addChain(chainParam).then(() => {
            // 返回值为null
            handler(6)
          }).catch((addError:any) => {
            handler(6)
            console.log('添加链异常:', addError)
          })
        } else {
          handler(6)
          console.log('切换链异常:', changeError)
        }
      })
    }
  } 

  return toggleChainHandle

}

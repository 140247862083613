import React, { useContext, useRef   } from 'react'
import { ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import { UserSvg, Link2Svg, CopySvg } from '@/utils/icoStatic'
import { _substrAddress, _numComma } from '@/utils/comMethod'
import useOnClickOutside from '@/hooks/useOutsideClick'
import { RouterProps } from '@/utils/interface'
import { WalletSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import useToggle from '@/hooks/useToggle'
import { ConnectStyle } from './styled'
import Copy from '@/components/Copy'
import Text from '@/components/Text'

const Connect:React.FC<RouterProps> = ({ t })=> {

  const dropRef:any = useRef()

  const { _getChainProperty, _getHashQueryAddress } = useCommon()

  const { account, spaceName, isPC, chainSign, nativeBalance } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const currentName = _getChainProperty(chainSign, 'currency')

  const [ status, setStatus ] = useToggle(false)

  useOnClickOutside(dropRef, status ? setStatus : undefined)

  // 操作 - 弹窗
  const popHandle = () => {
    configDispatch({ type: 'changePop', payload: 'selectWallteState' })
  }

  // 操作 - 跳转
  const jumpHandle = () => {
    const url = _getHashQueryAddress(account, 'address')
    window.open(url)
  }

  return (
    <ConnectStyle ref={dropRef}>
      {account ? (
        <div className='wallet-bar' onClick={setStatus}>
          { isPC && <WalletSvg /> }
          <Text type='english' color='white'>{spaceName ? spaceName : _substrAddress(account, isPC ? 6 : 4)}</Text>
        </div>
      ) : (
        <>{ isPC && <Text clsName='connect-bar' color='white' callback={popHandle}>{t('Button.Connect')}</Text> }</>
      )}
      <div className={`info-bar ${!status ? 'dn' : ''}`}>
        <div>
          <div className='address-key'>
            <UserSvg />
            <Text type='english' size={isPC ? '18' : '14'}>{spaceName ? spaceName : _substrAddress(account, 4)}</Text>
          </div>
          <figure>
            <Copy address={account}><CopySvg /></Copy>
            <Link2Svg onClick={jumpHandle} />
          </figure>
        </div>
        <div>
          <div>
            <Text type='specialNumber' kind='Bold' size={isPC ? '18' : '14'}>{_numComma(nativeBalance,4)}</Text>
            <Text type='specialEnglish' kind='Bold' size={isPC ? '18' : '14'}>{currentName}</Text>
          </div>
        </div>
      </div>
    </ConnectStyle>
  )
}

export default withRouter(Connect)
  
import React, { useReducer } from 'react'
import { FaucetContext, FaucetDispatchContext } from '@/utils/contextStatic'
import { faucetState, faucetReducer } from '@/reducer/faucet'
import useInitFaucet from '@/hooks/useInit/useInitFaucet'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { FaucetStyle } from './FaucetCss'
import useInit from '@/hooks/useInit'
import Content from './Content'

const Faucet:React.FC<RouterProps> = ({ params }) => {

  const [ faucetData, faucetDispatch ] = useReducer(faucetReducer, faucetState)

  useInitFaucet(faucetData, faucetDispatch, params)
  
  const { tokenList } = faucetData

  useInit(params.chainSymbol)

  return (
    <FaucetStyle>
      <FaucetContext.Provider value={faucetData}>
        <FaucetDispatchContext.Provider value={faucetDispatch}>
          <div className='bg-bar'></div>
          <main>{tokenList.length > 0 && <Content />}</main>
        </FaucetDispatchContext.Provider>
      </FaucetContext.Provider>
    </FaucetStyle>
  )
}

export default withRouter(Faucet)

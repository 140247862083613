import { rem } from '@/theme'
import styled from 'styled-components'

const ConnectStyle = styled.div`
	position: relative;
	background: ${({ theme }) => theme.primary500};
	border-radius: 9999px;
	div.wallet-bar {
		${({ theme }) => theme.align};
		height: 100%;
	}
	div.info-bar {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: ${({ theme }) => theme.white};
		& > div:nth-child(1) {
			${({ theme }) => theme.align_between};
			div.address-key {
				${({ theme }) => theme.align};
			}
			figure {
				${({ theme }) => theme.align};
			}
		}
		& > div:nth-child(2) {
			background: ${({ theme }) => theme.address_bg};
			text-align: center;
			& > div:nth-child(1) {
				${({ theme }) => theme.align_center};
			}
		}
	}
	// PC
	@media screen and (min-width: 540px) { 
		width: 178px;
		height: 40px;
		margin-right: 12px;
		padding: 0 15px;
		div.wallet-bar {
			cursor: pointer;
			svg {
				width: 18px;
				height: 18px;
				margin-right: 10px;
			}
		}
		div.connect-bar {
			${({ theme }) => theme.align_center};
			height: 100%;
		}
		div.info-bar {
			top: 50px;
			padding: 12px;
			border-radius: 12px;
			& > div:nth-child(1) {
				div.address-key {
					svg {
						width: 20px;
						height: 20px;
						margin-right: 8px;
					}
				}
				figure {
					padding-left: 37px;
					svg {
						width: 24px;
						height: 24px;
						margin-left: 8px;
						cursor: pointer;
					}
				}
			}
			& > div:nth-child(2) {
				margin-top: 12px;
				padding: 12px;
				& > div:nth-child(1) {
					& > div:nth-child(1) {
						margin-right: 4px;
					}
				}
			}
		}
	}
	// H5
	@media screen and (max-width: 540px) { 
		height: ${rem(24)};
		margin-right: ${rem(12)};
		padding: 0 ${rem(10)};
		div.info-bar {
			top: ${rem(50)};
			padding: ${rem(10)};
			border-radius: ${rem(12)};
			& > div:nth-child(1) {
				div.address-key {
					svg {
						width: ${rem(18)};
						height: ${rem(18)};
						margin-right: ${rem(8)};
					}
				}
				figure {
					padding-left: ${rem(17)};
					svg {
						width: ${rem(20)};
						height: ${rem(20)};
						margin-left: ${rem(8)};
					}
				}
			}
			& > div:nth-child(2) {
				margin-top: ${rem(12)};
				padding: ${rem(6)};
				& > div:nth-child(1) {
					& > div:nth-child(1) {
						margin-right: ${rem(4)};
					}
				}
			}
		}
	}
`

export {
	ConnectStyle
}





import { createContext } from 'react'
import { configState } from '@/reducer/config'
import { faucetState } from '@/reducer/faucet'

// 全局配置 - 数据
const ConfigContext = createContext(configState)

// 全局配置 - 方法
const ConfigDispatchContext:any = createContext(null)

// Faucet - 数据
const FaucetContext = createContext(faucetState)

// Faucet - 方法
const FaucetDispatchContext:any = createContext(null)

export {
  ConfigDispatchContext,
  FaucetDispatchContext,
  FaucetContext,
  ConfigContext
}

// 最大值
const maxUint256 = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

// 原生代币
const chainCoin = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

// 域名
const hostname = window.location.hostname.replace('www.', '')

// 多语言列表
const langList:any = {
  'en':{name : 'English', sign: 'EN', text: 'en-us'},
  'zh-CN':{name : '简体中文', sign: 'CN', text: 'zh-cn'},
  'zh-HK': {name : '繁体中文', sign: 'HK', text: 'zh-hk'},
}

export {
  maxUint256,
  chainCoin,
  hostname,
  langList
}

import { rem } from '@/theme'
import styled from 'styled-components'

const sizeConfig:any = {
	'small': { bh: 40, iw: 16, ih: 16 },
	'normal': { bh: 50, iw: 18, ih: 18 },
	'large': { bh: 60, iw: 26, ih: 26 },
}

const FormButtonStyle = styled.button<{size:string, $stateless:boolean, kind:string}>`

	${({ theme }) => theme.align_center};
	border-radius: 9999px;
	&.primary:disabled {
		background: ${({ theme }) => theme.gray200};
		pointer-events: none;
		border-width: 0;
		${({ $stateless }) => $stateless && 'pointer-events:none'};
		div {
			color: ${({ theme }) => theme.button_ds}; 
		}
	}
	&.primary, &.loading  {
		background: ${({ theme }) => theme.primary500};
	}
	&.cancel {
		border: 1px solid ${({ theme }) => theme.gray200};
	}
	&.loading {
		svg {
			color: ${({ theme }) => theme.white};
		}
	}
	// pc
	@media screen and (min-width: 540px){ 
		${({ size }) => `
			height: ${sizeConfig[size]['bh']}px;
			padding: 0 16px;
		`}
		&.primary, &.loading {
			img.icon, & > svg {
				width: 	${({ size }) => sizeConfig[size]['iw'] + 'px'};
				height: ${({ size }) => sizeConfig[size]['ih'] + 'px'};
				margin-right: 8px;
			}
		}
		&.primary:hover, &.loading:hover {
			background: ${({ theme }) => theme.primary700};
		}
	}

	// H5
	@media screen and (max-width: 540px){
		${({ size }) => `
			height: ${rem(sizeConfig[size]['bh'])};
			padding: 0 ${rem(16)};
		`}
		&.primary, &.loading {
			img.icon, & > svg {
				width: 	${({ size }) => `${rem(sizeConfig[size]['iw'])}`};
				height: ${({ size }) => `${rem(sizeConfig[size]['ih'])}`};
				margin-right: ${rem(8)};
			}
		}
	}
`


export {
	FormButtonStyle
}





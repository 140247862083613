const wallet =  window.localStorage.getItem('wallet')

const configState:any = {
  // PC端
  isPC: !navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i),
  // 主题 true: 黑夜模式  false: 白昼模式
  isDaskMode: false,
  // 网络名称 
  chainName: 'Mumbai',
  // 链标识 1:Eth  128:Heco  56:Bsc
  chainSign: 80001, 
  // spaceID 绑定主域名
  spaceName: '',
  // 账户
  account: '',
  // 链ID  0x1:Eth  0x80:Heco  0x38:Bsc
  chainId: '',
  // 钱包类型
  walletType: wallet ? wallet : 'MetaMask',
  // 弹框展示配置
  popShowConfig: {
    // 选择钱包
    selectWallteState: false,
  },
  // 支持链列表
  supportChainList: [],
  // 代币价格列表
  tokenPriceList: [],
  // Toast配置
  toastConfig: {
    status: false,
    address: '',
    kind: 'wait',
    text: '',
    timing: false
  },
  // 状态更新
  updateConfig: {
    // 更新 - 原生代币
    UPDATE_BALANCE: true,
  },
  // 原生资产
  nativeBalance: '0',
  // 路由表示
  routerSign: ''
}

const configReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    case 'changeChainList':
      updateData['supportChainList'] = payload
      const chainInfo = payload.find((item:any) => Number(updateData['chainId']) === item['chain_id'])
      if(chainInfo) {
        updateData['chainName'] = chainInfo['chain_name']
        updateData['chainSign'] = chainInfo['chain_id']
      }
      break
    case 'chainChain':
      updateData['chainId'] = payload
      if(updateData['supportChainList'].length > 0) {
        const chainInfo = updateData['supportChainList'].find((item:any) => Number(payload) === item['chain_id'])
        if(chainInfo) {
          updateData['chainName'] = chainInfo['chain_name']
          updateData['chainSign'] = chainInfo['chain_id']
        }
      }
      break;
    case 'changeToast':
      updateData['toastConfig'] = Object.assign(updateData['toastConfig'], payload)
      break;
    case 'changeWallet':
      updateData['walletType'] = payload
      localStorage.setItem('wallet', payload)
      break;
    case 'changeState':
      updateData[payload] = !updateData[payload]
      break;
    case 'changeUpdate': 
      updateData['updateConfig'] = { ...updateData['updateConfig'], ...payload }
      break;
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  configReducer,
  configState
}
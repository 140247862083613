import { useMemo, useContext } from 'react'
import { ThemeProvider as StyledComponentsThemeProvider, DefaultTheme } from 'styled-components'
import { ConfigContext } from '@/utils/contextStatic'
import resetStyle from './resetStyle'
import layouts from './layouts'
import colors from './colors'

// 初始化重置样式
export const FixedGlobalStyle = resetStyle

// 封装引入公共变量
export default function ThemeProvider({ children }: { children: JSX.Element }) {
  const { isDaskMode } = useContext(ConfigContext)
  const themeObject = useMemo(() => theme(isDaskMode), [isDaskMode])
  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

// h5大小计算
export const rem = ($px:number) => {
  return $px / 75 + 'rem'
}

// 设置公共样式变量
export function theme(isDaskMode: boolean): DefaultTheme {
  return {
    ...colors(isDaskMode),
    ...layouts(),
  }
}
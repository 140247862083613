import { useEffect, useContext } from 'react'
import { ConfigDispatchContext, ConfigContext } from '@/utils/contextStatic'
import usePublicMonitor from '@/hooks/usePublic/usePublicMonitor' 
import usePublicWallet from '@/hooks/usePublic/usePublicWallet' 
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { chainConfigList } from '@/utils/chainStatic'

/**
 * 初始化 - APP
 */

export default function useInitApp() {

  const configData = useContext(ConfigContext)

  const { _connectMetaMask } = usePublicWallet()

  const { _getBalacneOperate } = usePublicErc20()

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { _metamaskInit, _metaMaskMonitor } = usePublicMonitor()

  const { chainSign, chainId, account, updateConfig } = configData

  const { UPDATA_BALANCE } = updateConfig

  // 获取 - 支持的链列表列表
  const requestChainList = () => {
    const list:any = []
    const chainList = Object.keys(chainConfigList)
    for(const item of chainList) {
      const { chainId, chainName, name, symbol, rpcUrls, blockExplorerUrls, navText } = chainConfigList[item]
      const themp = {
        chain_id: +chainId,
        chain_name: chainName,
        chain_symbol: name,
        currency: symbol,
        rpc_node: rpcUrls,
        block_explorer: blockExplorerUrls,
        nav_text: navText
      }
      list.push(themp)
    }
    configDispatch({ type: 'changeChainList', payload: list })
  }

  useEffect(() => {
    // 获取 - 支持的链列表列表
    requestChainList()
    // 初始化 - MetaMask系列
    _metamaskInit()
    // 监听 - MetaMask系列
    _metaMaskMonitor()
    // 弹出 - MetaMask系列钱包
    _connectMetaMask().catch((err) => console.log('用户拒绝链接钱包', err))
  }, [])

  // 更新 - 原生资产
  useEffect(() => {
    const isMatch = chainSign === +chainId
    const getNativeBalance = async() => {
      let balance:any = '0'
      if(account) balance = await _getBalacneOperate()
      configDispatch({ type: 'nativeBalance', payload: balance })
    }
    if(isMatch) getNativeBalance()
  }, [account, chainId, UPDATA_BALANCE])

}

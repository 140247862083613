import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import Connect from '@/components/Header/Connect'
import Logo from '@/components/Header/Logo'
import More from '@/components/Header/More'
import Nav from '@/components/Header/Nav'
import { HeaderStyle } from './styled'

const Header:React.FC = ()=> {

  const { isPC, supportChainList } = useContext(ConfigContext)

  return (
    <HeaderStyle>
      <div className='left-bar'>
        <Logo />
        { isPC && supportChainList.length > 0 && <Nav /> }
      </div>
      <div className='right-bar'>
        <Connect />
        <More />
      </div>
    </HeaderStyle>
  )
}

export default Header

// 图片地址
const tokenIconsDomain = 'https://static.ccdex.finance'

// 原生代币
const chainCoin = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

// 代币地址列表
const tokenAddressList:any = {
  // Sepolia
  11155111: {
    "USDT": "0x674761786a4bed799b26e0254e567b681ee29719",
    "USDC": "0xCEAad24900C49a20a7f4B24aB47655D34E3B36F3",
    "DAI": "0xCE22A534686184bae42a02DedeC1e30744eb0440",
    "BTC": "0xe0B75CD04E03dB5792CabD4aF3B8C95e6C3e956e"
  },
  // Mumbai
  80001: {
    "USDT": "0x9f2268c26621604c6e72896b4051d07ff8b7dd58",
    "USDC": "0x2BF0adFC27aA3Af9b9A225F1a80FDA3C5D6fFB96",
    "DAI": "0x40c6e9F80F5E5E71a60534337331779822335df1",
    "BTC": "0x9166b7d53410f2f4b47048dFd1DD9357d3DA6e93"
  },
  // Bsctest
  97: {
    "USDT": "0x381C8c119eaE364cC5cF3386e3B9d0f564A32aca",
    "USDC": "0xa67cEe26A1B975730F04d2a57f604AbBF51980b0",
    "DAI": "0xE45566d533f2882F5cFDDbA50038476c6BE42EEB",
    "BTC": "0xce32E552E80e92898D496648C7C4f4Cf000D45c2"
  },
}

export {
  tokenAddressList,
  tokenIconsDomain,
  chainCoin
}
import { useContext } from 'react'
import { ConfigDispatchContext, ConfigContext } from '@/utils/contextStatic'
import usePublicWallet from '@/hooks/usePublic/usePublicWallet'

/**
 * 钱包监听
 */

export default function usePublicMonitor() {

  const { _getEthereum } = usePublicWallet()

  const { walletType } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  // 变更 - 账户
  const changeAccount = (accounts:string[]) => {
    const val = accounts[0] ? accounts[0].toLowerCase() : ''
    configDispatch({ type: 'account', payload: val })
  }

  // MetaMask系列初始
  const _metamaskInit = async() => {
    const { ethereum }:any = window
    if (ethereum) { 
      // 获取网络ID
      ethereum.request({ method: 'eth_chainId' }).then((chainId:string) => {
        configDispatch({ type: 'chainChain', payload: chainId })
      })
      // 获取用户账户
      ethereum.request({ method: 'eth_accounts' }).then((accounts:string[]) => {
        changeAccount(accounts)
      })  
    }
  }

  // MetaMask系列监听
  const _metaMaskMonitor = async() => {
    let ethereum:any = _getEthereum(walletType)
    let maskEthereum:any = window.ethereum
    let bitkeepEthereum:any = window.bitkeep && window.bitkeep.ethereum
    let okxEthereum:any = window.okxwallet
    if (ethereum) {
      if(okxEthereum) okxEthereum.removeAllListeners()
      if(maskEthereum) maskEthereum.removeAllListeners()
      if(bitkeepEthereum) bitkeepEthereum.removeAllListeners()
      // 用户账户改变的时如：切换账户、断开账户
      ethereum.on('accountsChanged', (accounts: string[]) => {
        changeAccount(accounts)
        configDispatch({ type: 'spaceName', payload: '' })
      })
      // 监听链切换
      ethereum.on('chainChanged', (chainId:string) => {
        configDispatch({ type: 'chainChain', payload: chainId })
        configDispatch({ type: 'spaceName', payload: '' })
      })
    }
  }

  return {
    _metamaskInit,
    _metaMaskMonitor
  }
}


import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigDispatchContext, FaucetContext } from '@/utils/contextStatic'
import useContractMock from '@/hooks/useContract/useContractMock'

/**
 * 业务 - Faucet
 */
export default function useBusinessFaucet(handle:any) {

  const t:any = useTranslation()['t']

  const faucetData:any = useContext(FaucetContext)

  const { tokenList, tabKey, mintLimitAmount } = faucetData

  const configDispatch:any = useContext(ConfigDispatchContext)

  const tokenInfo = tokenList.find((item:any) => item.key === tabKey)

  const { _setMint } = useContractMock()

  /**
   * 铸造 - 代币
   * @param {String} callback    回调函数
   * @param {String} handle      更新状态
   */ 
  const _setMintHandle = async(callback:any) => {
    handle(2)
    let text = `${t("Button.Transferred")} ${mintLimitAmount} ${tabKey}`
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    _setMint(tokenInfo['value']).then(async(res:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
      const pledgeInfo = await res.wait()
      if(pledgeInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: res.hash, text} })
        callback(res)
      } else {
        handle(3)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
      }
    }).catch((err:any)=>{
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handle(3)
      console.log({err})
    })
  }

  return {
    _setMintHandle
  }
}

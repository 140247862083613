import { Alert } from 'antd'
import React, { useContext, useState } from 'react'
import { ConfigContext, ConfigDispatchContext, FaucetContext, FaucetDispatchContext } from '@/utils/contextStatic'
import useBusinessMock from '@/hooks/useBusiness/useBusinessMock'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import { _formatDate, _substrAddress } from '@/utils/comMethod'
import useBtnFaucet from '@/hooks/useBtn/useBtnFaucet'
import { _hashQueryAddress } from '@/utils/urlMethod'
import useBtnChain from '@/hooks/useBtn/useBtnChain'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import FormInput from '@/components/FormInput'
import { FaucetContentStyle } from './styled'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import ScTab from '@/components/ScTab'
import Text from '@/components/Text'

const FaucetContent:React.FC<RouterProps> = ({ t, params }) => {

  const { chainSymbol } = params

  const { _isAddress } = usePublicEthers()
  
  const { isPC } = useContext(ConfigContext)

  const { _getChainProperty, linkList } = useCommon()

  const faucetDispatch:any = useContext(FaucetDispatchContext)
  
  const configDispatch:any = useContext(ConfigDispatchContext)
  
  const { tabKey, tabValue, tokenList, mintLimitAmount, navText, selectNetwork, transactionsList, receiveAddress, nextMintLastTime } = useContext(FaucetContext)

  const currentName = _getChainProperty(selectNetwork, 'currency')

  // 2：加載中 3:确认 5: 钱包未链接 6: 链不匹配 
  const [ btnStatus, setBtnStatus ] = useState<number>(3)

  // 文本参数
  const textParam = { 
    tokenAmount: mintLimitAmount, 
    chainName: chainSymbol.toUpperCase(), 
    tokenName: tabKey
  }

  // 切链操作
  const toggleChainHandle = useBtnChain(selectNetwork, setBtnStatus)

  // 铸造操作
  const { _setMintHandle } = useBusinessMock(setBtnStatus)

  useBtnFaucet(setBtnStatus)

  // 操作 - 更新
  const changeHandle = (val:string) => {
    faucetDispatch({ type: 'receiveAddress', payload: val })
  }

  // 操作 - 切换
  const tabHandle = (key:string) => {
    faucetDispatch({ type: 'tabKey', payload: key })
    const tokenInfo = tokenList.find((item:any) => item.key === key)
    faucetDispatch({ type: 'tabValue', payload: tokenInfo['value'] })
  }

  // 操作 - 更新
  const updateHandle = (item:any) => {
    setBtnStatus(3)
    faucetDispatch({ type: 'receiveAddress', payload: '' })
    const { hash } = item
    const cloneData = transactionsList.slice(0)
    const time = new Date().getTime()
    cloneData.push({ hash, time })
    window.localStorage.setItem(`${receiveAddress.toLowerCase()}_${tabValue.toLowerCase()}_transactions`, JSON.stringify(cloneData))
    faucetDispatch({ type: 'transactionsList', payload: cloneData })
  }

  // 操作 - 跳转
  const viewHashHandle = (address:any) => {
    const url = _hashQueryAddress(address, selectNetwork)
    window.open(url)
  }

  // 操作 - 跳转
  const receiveGasHandle = () => {
    window.open(linkList[`${chainSymbol.toUpperCase()}Gas`])
  }

  // 按钮处理
  const btnHandle = () => {
    switch(btnStatus) {
      // 铸造
      case 3:
        _setMintHandle(updateHandle)
        break;
      // 链接
      case 5:
        configDispatch({ type: 'changePop', payload: 'selectWallteState' })
        break;
      // 切链
      case 6:
        toggleChainHandle()
        break;
      default:
    }
  }

  // 判断 - 按钮状态
  const openHandle = () => {
    const isPass = receiveAddress &&
                   nextMintLastTime === 0 &&
                   _isAddress(receiveAddress)
    if(isPass) return true
    return false
  }

  // 展示 - 按钮信息列表
  const btnInfoList:any = {
    2: { text: t("Button.Loading"), kind: 'loading', disabled: true },
    3: { text: t('Button.SendMe', { tokenName: tabKey }), kind: 'primary', disabled: !openHandle() },
    5: { text: t("Button.Connect"), kind: 'primary', disabled: false },
    6: { text: t('Button.SwitchChain', { chain: chainSymbol.toUpperCase() }), kind: 'primary', disabled: false },
  }

  const { text, disabled, kind } =  btnInfoList[btnStatus]

  // 渲染 - 交易列表
  const transactionsRender = () => {
    const list:any = []
    transactionsList.forEach((item:any, index:number) => {
      const { hash, time } = item
      list.push(
        <tr key={`transactions_${index}`}>
          <td><Text size={isPC ? '14' : '12'} color='gray600' underline='1' callback={() => viewHashHandle(hash)}>{isPC ? hash : _substrAddress(hash, 8)}</Text></td>
          <td><Text size={isPC ? '14' : '12'} color='gray600'>{_formatDate(time, isPC ? 1 : 2)}</Text></td>
        </tr>
      )
    })
    return list
  }

  return (
    <FaucetContentStyle>
      <Text kind='Bold' size={isPC ? '40' : '22'}>{t('Title.ChainFaucet', { name: navText })}</Text>
      <Text kind='Bold' size={isPC ? '20' : '14'}>{t('Content.ChainFaucetDesc', textParam)}</Text>
      { nextMintLastTime !== 0 && <Alert type="error" description={<Text size={isPC ? '14' : '12'}>{t('Warning.ReceiveHint', textParam)}</Text>} /> }
      <div className='content-bar'>
        <div className='tab-item'>
          <ScTab data={tokenList} currentKey={tabKey} callback={tabHandle} />
          <Text kind='Bold' size={isPC ? '16' : '14'} color='primary500' callback={receiveGasHandle}>{t('Content.ReceiveGas', { chainName: chainSymbol.toUpperCase(), tokenName: currentName })}</Text>
        </div>
        <div className='form-item'>
          <FormInput type='normal' placeholder={t('Placeholder.AddressInput')} size={isPC ? 'large' : 'normal'} init={receiveAddress} callback={(val:string) => changeHandle(val)} />
          <FormButton callback={btnHandle} kind={kind} disabled={disabled}>
            <Text type='specialEnglish' size={isPC ? '20' : '16'} color='white'>{text}</Text>
          </FormButton>
        </div>
        <div className='table-item'>
          <table>
            <colgroup>
              <col width={isPC ? '82%' : '60%'} />
              <col width={isPC ? '18%' : '40%'} />
            </colgroup>
            <thead>
              <tr>
                <th><Text color='gray500'>{t('Field.Transactions')}</Text></th>
                <th><Text color='gray500'>{t('Field.Time')}</Text></th>
              </tr>
            </thead>
            <tbody>{transactionsList.length > 0 ? transactionsRender() : <tr><td colSpan={2}><Text>-</Text></td></tr> }</tbody>
          </table>
        </div>
      </div>
    </FaucetContentStyle>
  )
}

export default withRouter(FaucetContent)

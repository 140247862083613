import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'

/**
 * API - Mock
 */
export default function useContractMock() {

  const { _outPutFormat } = usePublicEthers()

  const { _getContract, _getDecimals } = usePublicErc20()

  /**
   * 获取 - 用户下一次可以mint的剩余时间
   * @param {String} tokenAddress     代币地址
   * @param {String} receiveAddress   接收地址
   */ 
  const _getTimeUntilNextMint = async(tokenAddress:string, receiveAddress:string) => {
    console.log('合约【获取 - 用户下一次可以mint的剩余时间(入参)】:', { tokenAddress, receiveAddress })
    try {
      const mockContractExample:any = await _getContract(tokenAddress, 'MOCKERC20ABI')
      return mockContractExample["timeUntilNextMint"](receiveAddress)
    } catch (error) {
      console.error('合约调用异常【获取 - 用户下一次可以mint的剩余时间】:', error)
    }
  }

  /**
   * 获取 - 用户可以领取某一代币数量
   * @param {String} tokenAddress     代币地址
   */ 
  const _getMintLimit = async(tokenAddress:string) => {
    console.log('合约【获取 - 用户可以领取某一代币数量(入参)】:', { tokenAddress })
    try {
      let decimals = 18 
      const mockContractExample:any = await _getContract(tokenAddress, 'MOCKERC20ABI')
      decimals = await _getDecimals(tokenAddress)
      const amount = await mockContractExample["mintLimit"]()
      return _outPutFormat(amount, decimals)
    } catch (error) {
      console.error('合约调用异常【获取 - 用户可以领取某一代币数量】:', error)
    }
  }

  /**
   * 获取 - 每次mint的间隔
   * @param {String} tokenAddress     代币地址
   */ 
  const _getMintInterval = async(tokenAddress:string) => {
    console.log('合约【获取 - 每次mint的间隔(入参)】:', { tokenAddress })
    try {
      const mockContractExample:any = await _getContract(tokenAddress, 'MOCKERC20ABI')
      return mockContractExample["mintInterval"]()
    } catch (error) {
      console.error('合约调用异常【获取 - 每次mint的间隔】:', error)
    }
  }

  /**
   * 操作 - 铸造并发送代币
   * @param {String} tokenAddress     代币地址
   */ 
  const _setMint = async(tokenAddress:string) => {
    console.log('合约【操作 - 铸造并发送代币(入参)】:', { tokenAddress })
    try {
      const mockContractExample:any = await _getContract(tokenAddress, 'MOCKERC20ABI')
      return mockContractExample["mint"]()
    } catch (error) {
      console.error('合约调用异常【操作 - 铸造并发送代币】:', error)
    }
  }

  return {
    _getTimeUntilNextMint,
    _getMintInterval,
    _getMintLimit,
    _setMint
  }
}

import { ethers } from "ethers"

/**
 * Ethers基础方法 - 版本6.6.7
 */
export default function usePublicEthers() {

  // 输出格式转换
  const _outPutFormat = (num: number | string, decimal = 18) => {
    return ethers.formatUnits(num, decimal).toString()
  }

  /**
   * 集成ethers.js - 基于浏览器
   */
  const _getBrowserProvider = () => {
    const ethereum: any = window.ethereum
    return new ethers.BrowserProvider(ethereum)
  }

  /**
   * 集成ethers.js - 基于JSON-RPC
   * @param {String}  rpc              JSON-RPC
   */
  const _getJsonRpcProvider = (rpc: string) => {
    return new ethers.JsonRpcProvider(rpc)
  }

  /**
   * 对Provider进行签名
   * @param {String}  account          用户地址
   */
  const _getSigner = async (account?: string) => {
    const provider = await _getBrowserProvider()
    if (account) {
      return provider.getSigner(account)
    } else {
      return provider.getSigner()
    }
  }

  /**
   * 验证钱包地址
   * @param {String}  address        钱包地址
   */
  const _isAddress = (address: string) => {
    return ethers.isAddress(address)
  }

  return {
    _getJsonRpcProvider,
    _getBrowserProvider,
    _outPutFormat,
    _isAddress,
    _getSigner
  }
}

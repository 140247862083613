import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { NavStyle } from './styled'


const Nav:React.FC<RouterProps> = ({ navigate })=> {

  const { linkList } = useCommon() 

  const configData = useContext(ConfigContext)

  const { routerSign, supportChainList } = configData

  const navList:any = [ 'mumbai', 'sepolia', 'bsctest' ]

  // 字段展示
  const navListRender = () => {
    const list:any = []
    navList.forEach((name:any, index:number) => {
      const chainInfo = supportChainList.find((item:any) => item.chain_symbol === name)
      const isCurrent = name === routerSign
      list.push(
        <li key={`navList${index}`}>
          <Text kind={isCurrent ? 'Bold' : 'Light'} type='specialEnglish' size='16' hovercolor='text_main' callback={() => jumpHandle(name)}>{chainInfo['nav_text']}</Text>
        </li>
      )
    })
    return list
  }

  // 跳转
  const jumpHandle = (name:string) => {
    navigate(`${linkList['Faucet']}/${name}`)
  }

  return (
    <NavStyle>
      <ul>{navListRender()}</ul>
    </NavStyle>
  )
}

export default withRouter(Nav)

import React from 'react'
import { message } from 'antd'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { CopyStyle } from './styled'

interface ChildProps extends RouterProps {
  address: string
  children: any
}

const Copy:React.FC<ChildProps> = ({ t, address, children }) => {

  const key = Math.random().toString(36).substring(2, 4)

  // 复制
  const copyAccessKey = () => {
    const copyDOM:any = document.querySelector(`.copyDom${key}`) 
    const range = document.createRange()              
    window.getSelection()?.removeAllRanges()           
    range.selectNode(copyDOM)                            
    window.getSelection()?.addRange(range)            
    var successful = document.execCommand('copy')      
    if(successful){
      message.success(t('State.CopySuc'))
    }else{
      message.warning(t('State.CopyErr'))
    }
    window.getSelection()?.removeAllRanges()           
  }

  return (
    <CopyStyle className='copy-module' onClick={copyAccessKey}>
      <input className={`copyDom${key}`} value={address} readOnly={true} />
      { children }
    </CopyStyle>
  )
}

export default withRouter(Copy)
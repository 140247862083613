import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigContext } from '@/utils/contextStatic'
import { _collectLink } from '@/utils/urlMethod'

/**
 * 公共方法
 */
export default function useCommon() {

  const { i18n } = useTranslation()

  const { chainSign, supportChainList } = useContext(ConfigContext)

  // 是否为英文
  const isEnglish: boolean = !['zh-CN', 'zh-HK'].includes(i18n.language)

  // 链接集合
  const linkList:any = _collectLink(i18n.language) 

  /**
   * 获取 - 链属性
   * @param {Number|String} sign      // 链标识
   * @param {String} field            // 字段名
   */
  const _getChainProperty = (sign:number|string, field:string, kind='chain_id') => {
    let value = sign
    if(kind === 'chain_id') value = +sign
    const chainInfo = supportChainList.find((item:any) => item[kind] === value)
    return chainInfo ? chainInfo[field] : ''
  }

  /**
   * 获取 - hash查詢地址
   * @param {String} address     // 地址
   * @param {String} state       // 查询类型 tk、token、address
   */
  const _getHashQueryAddress= (address:string, state='tx') => {
    const chainInfo = supportChainList.find((item:any) => item['chain_id'] === chainSign)
    return `${chainInfo['block_explorer']}/${state}/${address}`
  }

  return {
    _getHashQueryAddress,
    _getChainProperty,
    isEnglish,
    linkList
  }
}

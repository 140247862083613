/**
 * 获取地址集合
 * @param {String} lang   // 语言
 */
const _collectLink = (lang:string) => {
  const setlangPram = `?lang=${lang}`
  return {
    'Faucet': '/faucet',
    'Home': `/${setlangPram}`,
    'MUMBAIGas': 'https://mumbaifaucet.com',
    'SEPOLIAGas': 'https://sepoliafaucet.com',
    'BSCTESTGas': 'https://www.bnbchain.org/en/testnet-faucet',
  } 
}

/**
 * hash查詢地址
 * @param {String} address     // 地址
 * @param {Number} chainId     // 11155111/80001/97
 * @param {String} state       // 查询类型 tk、token、address
 */
const _hashQueryAddress= (address:string, chainId:number, state='tx') => {
	if(chainId === 11155111) return `https://sepolia.etherscan.io/${state}/${address}`
  if(chainId === 80001) return `https://mumbai.polygonscan.com/${state}/${address}`
  if(chainId === 97) return `https://testnet.bscscan.com/${state}/${address}`
	return ''
}

export {
  _hashQueryAddress,
  _collectLink,
}

// input框输入校验
const _check_proving = (ele:string, index:number) => {
	let ind = index ? index : 18
	let status:string = ele?.replace(/[^\d.]/g, '')
		.replace(/^\./g, '')
		.replace(/\.{ind,}/g, '.')
		.replace('.', '$#$')
		.replace(/\./g, '')
		.replace('$#$', '.')
	const decimal = status.split('.')[1]
	if (decimal && decimal.length > ind) {
		status = status.slice(0, status.length - decimal.length + ind )
		if(status === '0') status = '0'
 	}
 	return status
}

// url校验 true:通过  false:未通过
const _check_url = (ele:string) => {
	const rule = /^((https|http|ftp|rtsp|mms)?:\/\/)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/
	if(rule.test(ele)) {
		return true
	} else {
		return false
	}
}

// 全英文校验
const _check_en = (ele:string) => {
	const rule = new RegExp("^[a-zA-Z]+$")
	if(rule.test(ele)) {
		return true
	} else {
		return false
	}
}


// 只能输入数字
const _check_num = (ele:string) => {
	return ele.replace(/\D/g,'')
}


// 替换字符串中的所有特殊字符（包含空格）
const _check_special = (ele:string) => {
	if(ele) {
		return ele.replace(/[\u4e00-\u9fa5]/g,'')
							.replace(/[^a-zA-Z0-9\w]/g,'')
	}
	return ele
}

export {
	_check_special,
	_check_proving,
	_check_num,
	_check_url,
	_check_en
}

import styled from 'styled-components'

const NavStyle = styled.nav`
	ul {
		${({ theme }) => theme.align};
		li {
			position: relative;
			white-space: nowrap;
			margin-right: 32px;
		}
	}
`

export {
  NavStyle
}
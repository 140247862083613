import { rem } from '@/theme'
import styled from 'styled-components'

const sizeConfig:any = {
	'small': { bh: 36 },
	'normal': { bh: 48 },
	'large': { bh: 56 },
}

const FormInputStyle = styled.div<{size:string, $maxvalue:string}>`

	position: relative;
	input {
		width: 100%;
		color: ${({ theme }) => theme.text_main};
		background: ${({ theme }) => theme.white};
		box-shadow: ${({ theme }) => theme.input_bs};
		border: 1px solid transparent;
		font-family: 'ENGLISH-SFPROTEXT-BOLD';
	}
	div.text-module {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: ${({ theme }) => theme.primary500};
		background: ${({ theme }) => theme.max_bg};
	}
	input:hover, input:focus {
		border-color: ${({ theme }) => theme.primary500} !important;
	}
	input:hover {
		box-shadow: ${({ theme }) => theme.input_hover_bs};
	}
	input:focus {
		box-shadow: ${({ theme }) => theme.input_focus_bs};
	}
	input:disabled {
		border-color: ${({ theme }) => theme.gray200} !important;
		background: ${({ theme }) => theme.gray200};
		box-shadow: none;
	}
	input::-webkit-input-placeholder { 
		font-family: 'ENGLISH-SFPROTEXT-REGULAR';
		color: ${({ theme }) => theme.gray400};
	}

	// PC
	@media screen and (min-width: 540px) { 
		input {
			height: ${({ size }) => `${sizeConfig[size]['bh']}px`};
			padding: ${({ $maxvalue }) => $maxvalue ? '0 70px 0 16px' : '0 16px'};
			font-size: 16px;
			border-radius: 8px;
		}
		div.text-module {
			right: 14px;
			padding: 4px 10px;
			border-radius: 5px;
		}
		input::-webkit-input-placeholder { 
			font-size: 16px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		font-size: 0;
		input {
			height: ${rem(48)};
			height: ${({ size }) => `${rem(sizeConfig[size]['bh'])}`};
			padding: 0 ${rem(16)};
			border-radius: ${rem(12)};
			font-size: ${rem(14)};
		}
		div.text-module {
			right: ${rem(10)};
			padding: ${rem(4)} ${rem(10)};
			border-radius: ${rem(6)};
		}
		input::-webkit-input-placeholder { 
			font-size: ${rem(14)};
		}
	}
`

export {
  FormInputStyle
}
import { rem } from '@/theme'
import styled from 'styled-components'

const FaucetStyle = styled.div`

	div.bg-bar {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		background: ${({ theme }) => theme.home_bg};
	}
	main {
		position: relative;
		z-index: 1;
	}

	// PC
	@media screen and (min-width: 540px){
		div.bg-bar {
			height: 530px;
		}
		main {
			${({ theme }) => theme.mainContent};
			margin-top: 40px;
		}
	}

	// H5
	@media screen and (max-width: 540px){
		div.bg-bar {
			height: ${rem(530)};
		}
		main {
			margin-top: ${rem(56)};
			margin-bottom: ${rem(90)};
			padding: 0 ${rem(16)};
		}
	}
	
`

export {
	FaucetStyle
}





import { useReducer } from 'react'
import { ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import { configState, configReducer } from '@/reducer/config'

// 引入全局公共数据库
export default function Provider({ children }: { children: JSX.Element }) {
  const [ data, dispatch ] = useReducer(configReducer, configState)
  return (
    <ConfigContext.Provider value={data}>
      <ConfigDispatchContext.Provider value={dispatch}>
        {children}
      </ConfigDispatchContext.Provider>
    </ConfigContext.Provider>
  )
}
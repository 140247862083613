// 链配置信息
const chainConfigList:any = {
  '0xaa36a7': {
    chainId: '0xaa36a7',
    chainName: 'Sepolia',
    name: 'sepolia',
    navText: 'ETHEREUM SEPOLIA',
    symbol: 'ETH',
    rpcUrls: ['https://eth-sepolia.g.alchemy.com/v2/demo'],
    blockExplorerUrls: ['https://sepolia.etherscan.io']
  },
  '0x13881': {
    chainId: '0x13881',
    chainName: 'Mumbai',
    name: 'mumbai',
    navText: 'POLYGON MUMBAI',
    symbol: 'MATIC',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com']
  },
  '0x61': {
    chainId: '0x61',
    chainName: 'BNB Smart Chain Testnet',
    name: 'bsctest',
    navText: 'BSC TESTNET',
    symbol: 'TBNB',
    rpcUrls: ['https://endpoints.omniatech.io/v1/bsc/testnet/public'],
    blockExplorerUrls: ['https://testnet.bscscan.com']
  }
}

export {
  chainConfigList
}
import styled from 'styled-components'
import { Suspense, useEffect, useState, useRef } from 'react'
import { Route, Routes, HashRouter, Navigate } from 'react-router-dom'
import useInitApp from '@/hooks/useInit/useInitApp'
import Header from '@/components/Header'
import Toast from '@/components/Toast'
import Faucet from './Faucet/Faucet'

const AppWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background: ${({theme}) => theme.web_bg};
`

const App = () => {

  useInitApp()

  const timer:any = useRef()

  const [ showStatus, setShowStatus ] = useState<boolean>(false)

  useEffect(() => {
    timer.current = setTimeout(() => setShowStatus(true), 100)
    return () => clearTimeout(timer.current)
  }, [])

  // 路由设置
  const _routeSet = () => {
    return (
      <AppWrapper>
        <HashRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Navigate to='/faucet/mumbai' replace />} />
            <Route path="/faucet/:chainSymbol" element={<Faucet />} />
          </Routes>
          <Toast />
        </HashRouter>
      </AppWrapper>
    )
  } 

  return (
    <Suspense fallback={null}>
      {showStatus &&  _routeSet()}
    </Suspense>
  )
}

export default App
